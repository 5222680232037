










import Vue from 'vue';
import countries from '@/countries';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    countryList: {
      type: String,
      required: true,
      validator(str: string) {
        return ['profiles'].includes(str);
      },
    },
    defaultOption: {
      type: Object,
      required: true,
    },
    // IDEA: prefix-country-code:bool
    // ^ prefix option text with "<CC>: "
    // IDEA: native-name:string<"prefix"|"only"|"suffix">
    // ^ "prefix": "[<CC>: ]<NativeName> (<CountryName>)"
    // ^ "only": "[<CC>: ]<NativeName>"
    // ^ "suffix": "[<CC>: ]<CountryName> (<NativeName>)"
    // This is required because otherwise the input wouldn't be reactive to v-model
    // Because this should neither have a default value nor type:
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    value: {},
  },
  computed: {
    // eslint-disable-next-line no-underscore-dangle
    __listeners(): any {
      return {
        ...this.$listeners,
        // This isn't used here because FormSelect already emits just the value
        // input: event => this.$emit('input', event.target.value)
      };
    },
    countries(): string[] {
      const list: string[] = countries[this.countryList];

      return list || [];
    },
    options() {
      const arr: any[] = [];

      this.countries.forEach((country) => {
        arr.push({
          value: country,
          text: this.$t(['country', country].join('.')),
        });
      });

      arr.sort((a, b) => {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      });

      return arr;
    },
  },
});
